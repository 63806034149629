<template>
  <div class="kopf" :class="{ 'bg-turkis': einkauf }">
    <notifications></notifications>
    <div class="container-fluid" :style="systemColor()">
      <div class="left-buttons" style="z-index: 5">
        <div class="navbar-button">
          <router-link to="/">
            <font-awesome-icon icon="fa-duotone fa-home" />
            <span>{{ $t("navbar.home") }}</span>
          </router-link>
        </div>
        <div class="navbar-button">
          <div class="clickable" @click="$router.go(-1)">
            <font-awesome-icon icon="fa-regular fa-angle-left" size="lg" />
            <span>{{ $t("navbar.back") }}</span>
          </div>
        </div>
        <div class="navbar-button">
          <div class="clickable" @click="$router.go(1)">
            <font-awesome-icon icon="fa-regular fa-angle-right" size="lg" />
            <span>{{ $t("navbar.forward") }}</span>
          </div>
        </div>
        <div class="navbar-button">
          <a href="#" onclick="window.location.reload(true);">
            <font-awesome-icon icon="fa-regular fa-sync" />
            <span>{{ $t("navbar.refresh") }}</span>
          </a>
        </div>
      </div>
      <div class="HeaderTitle text-left ml-5" style="padding-left: 150px">
        <span> {{ $CONST("CONFIG").APPNAME }}</span>
        <span style="font-size: 14px; padding-left: 10px"
          >{{ appVersion }}
        </span>
      </div>
      <div class="right-buttons">
        <div
          class="navbar-button"
          v-if="
            berechtigungen.m_geschaeftspartner.read &&
            $CONST('CONFIG').APPTYPE != 'DESKA'
          "
        >
          <router-link to="/geschaeftspartner/personenliste">
            <font-awesome-icon icon="fa-duotone fa-user-friends" />
            <span>{{ $t("navbar.people") }}</span>
          </router-link>
        </div>

        <div
          class="navbar-button"
          v-if="
            berechtigungen.m_geschaeftspartner.read &&
            $CONST('CONFIG').APPTYPE != 'DESKA'
          "
        >
          <router-link to="/geschaeftspartner/firmenliste">
            <font-awesome-icon icon="fa-duotone fa-building" />
            <span>{{ $t("navbar.companies") }}</span>
          </router-link>
        </div>

        <div
          class="navbar-button"
          v-if="
            berechtigungen.m_finanzen.read &&
            $CONST('CONFIG').APPTYPE != 'DESKA'
          "
        >
          <router-link :to="{ name: 'auftraege' }">
            <font-awesome-icon icon="fa-duotone fa-file-invoice-dollar" />
            <span>{{ $t("navbar.invoices") }}</span>
          </router-link>
        </div>

        <div
          class="navbar-button"
          v-if="
            berechtigungen.m_korrespondenz.read &&
            $CONST('CONFIG').APPTYPE != 'DESKA'
          "
        >
          <router-link :to="{ name: 'korrespondenz-liste' }">
            <font-awesome-icon icon="fa-duotone fa-print" />
            <span>{{ $t("navbar.correspondence") }}</span>
          </router-link>
        </div>

        <div
          class="navbar-button"
          v-if="
            berechtigungen.m_korrespondenz.read &&
            $CONST('CONFIG').APPTYPE != 'DESKA'
          "
        >
          <router-link :to="{ name: 'mail-liste' }">
            <font-awesome-icon icon="fa-duotone fa-envelope-open-text" />
            <span>{{ $t("navbar.emails") }}</span>
          </router-link>
        </div>

        <div
          class="navbar-button"
          v-if="
            berechtigungen.m_anmeldungen.read &&
            $CONST('CONFIG').APPTYPE != 'DESKA'
          "
        >
          <router-link :to="{ name: 'anmeldungen-liste' }">
            <font-awesome-icon icon="fa-duotone fa-sign-in-alt" />
            <span>{{ $t("navbar.registrations") }}</span>
          </router-link>
        </div>

        <div class="usersign" style="padding-left: 10px; top: 11px"></div>

        <SwitchLanguage />

        <div
          class="navbar-button"
          v-if="
            berechtigungen.g_einstellungen.read &&
            $CONST('CONFIG').APPTYPE != 'DESKA'
          "
        >
          <router-link to="/einstellungen/dashboard">
            <font-awesome-icon icon="fa-duotone fa-sliders-v-square" />
            <span>{{ $t("navbar.settings") }}</span>
          </router-link>
        </div>

        <div class="navbar-button">
          <div class="clickable" @click="logout()">
            <font-awesome-icon icon="fa-duotone fa-door-open" />
            <span>{{ $t("navbar.logout") }}</span>
          </div>
        </div>

        <div class="usersign" style="padding-left: 25px; top: 11px">
          <div class="dropdown show">
            <a
              href=""
              title="Profil"
              class="none-decoration"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <font-awesome-icon
                aria-hidden="true"
                icon="fa-duotone fa-user"
                style="margin-bottom: 0.35rem"
                size="2x"
              />
              <span>{{ name }}</span>
            </a>
            <div
              class="dropdown-menu"
              style="
                background-color: #124862;
                margin-left: 0px;
                margin-top: 5px;
                border-radius: 0 0 5px 5px;
              "
              aria-labelledby="dropdownMenuLink"
            >
              <a
                href=""
                class="dropdown-item"
                data-toggle="modal"
                data-target="#passwort-aendern-modal"
              >
                <span>{{ $t("navbar.passwordchange") }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <passwort-aendern-modal
      id="passwort-aendern-modal"
      @success="showSuccessNotification"
    >
    </passwort-aendern-modal>
  </div>
</template>

<script>
import store from "@/store";
import axios from "axios";
import server from "../server";
import PasswortAendernModal from "@/components/Modals/PasswortAendernModal";
import SwitchLanguage from "@/components/SwitchLanguage.vue";
import packageJson from "../../package.json";

export default {
  name: "Navbar",
  components: {
    PasswortAendernModal,
    SwitchLanguage,
  },
  props: {
    icon: { type: String, default: null },
    einkauf: { type: Boolean, default: false },
    finanzen: { type: Boolean, default: false },
    erinnerungConfig: {
      type: Object,
      default: () => {
        return { url: "", titel: "" };
      },
    },
  },
  data() {
    return {
      appVersion: packageJson.version,
    };
  },
  store,
  computed: {
    name() {
      return this.$store ? this.$store.getters.name : "";
    },
    artikeltypen() {
      return this.$store.state.artikeltypen;
    },
    logoutUrl() {
      if (process.env.NODE_ENV === "development") {
        return `${server.url}/logout/`;
      } else {
        return "/logout/";
      }
    },
  },
  methods: {
    async logout() {
      try {
        await axios.post(this.logoutUrl, {});
        window.location.href = "/";
      } catch (error) {
        console.log(error);
      }
    },
    showSuccessNotification() {
      this.$notify({
        type: "success",
        title: this.$t("notification.actionsuccessful"),
        text: this.$t("notification.thepasswordhasbeenchanged"),
      });
    },
    systemColor() {
      switch (this.$CONST("CONFIG").ENVIRONMENT) {
        case "DEV":
          return "background-color: #341C57";
        case "TEST":
          return "background-color: #1c6c65";
        default:
          return "background-color: #124862";
      }
    },
  },
};
</script>

<style lang="scss">
.bm-item-list {
  margin-left: 5%;
}
</style>
